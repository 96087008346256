import React, { useCallback, useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { ApiClient } from "../../services/api/ApiClient";
import { Forms } from "../../forms/Forms";
import { IDoctor } from "../../types/models/Doctor";
import { GREEN_DOT, RED_DOT } from "../../shared/StopLight";

export const DoctorsTab: React.FC = () => {
  const [doctors, setDoctors] = useState<IDoctor[]>([])
  const [doctor_id, setDoctorId] = useState<number | null>(null)
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false)

  const refresh = useCallback(() => {
    ApiClient.doctors.index().then((response) => {
      setDoctors(response)
    })
  }, [])
  useEffect(() => {
    refresh()
  }, [])

  const handleEdit = (doctor_id: number) => {
    setDoctorId(doctor_id)
    setIsFormVisible(true)
  }


  const handleDelete = (id: number) => {
    if (window.confirm('Seguro que desea eliminar el tratamiento?')) {
      ApiClient.doctors.delete(id).then(() => {
        refresh()
      })
    }
  }

  const handleFormClose = () => {
    setDoctorId(null)
    setIsFormVisible(false)
    refresh()
  }
  return (
    <>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h1>Doctores</h1>
        <Button className='rounded-0' size='sm' variant='outline-primary' onClick={() => setIsFormVisible(true)}>Nuevo</Button>
      </div>

      {isFormVisible && <Forms.Doctor doctor_id={doctor_id} onClose={() => handleFormClose()} />}
      <Table hover size='sm'>
        <thead>
          <tr>
            <th>Codigo</th>
            <th>Nombre</th>
            <th>Usuario</th>
            <th>Email</th>

            <th>Activo?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {doctors.map((doctor) => (
            <tr key={doctor.id}>
              <td>{doctor.id}</td>
              <td>{doctor.name}</td>
              <td>{doctor.username}</td>
              <td>{doctor.email}</td>

              <td>{doctor.active ? GREEN_DOT : RED_DOT}</td>
              <td>
                <Button className='rounded-0 mx-1 btn-xs' variant='outline-warning' onClick={() => handleEdit(doctor.id)}>Editar</Button>
                <Button className='rounded-0 btn-xs' variant='outline-danger' onClick={() => handleDelete(doctor.id)}>Eliminar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

  )
}